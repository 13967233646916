
import * as __star__ from '@clayui/alert';

const {
default: __default__,
__esModule
} = __star__;

export {
__esModule
};
export default __default__;
